<template>
    <div>
        <hero/>
        <contents :posts="posts" />
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        components: {
            Hero: () => import('@/components/blog/Hero'),
            Contents: () => import('@/components/blog/Contents')
        },
        data: () =>({
            posts: []
        }),
        async mounted(){
           const posts = await axios.get('https://ktt01.de/wordpress/?rest_route=/wp/v2/posts&categories=12&_embed&per_page=100')
            this.posts = posts.data

            this.$store.dispatch('app/setLink', -1)
            this.$store.dispatch('app/getTags')
        }
    }
</script>
